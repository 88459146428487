import { Base64 } from 'js-base64';

export default {
  install: (app, options) => {
    app.config.globalProperties.$trimWords = (text, length) => {
      if (typeof text !== 'string') {
        return text;
      }

      const parts = text.split(' ');

      let results = [];

      let total = 0;

      parts.forEach(part => {
        if ((total + part.length) <= length) {
          results.push(part);
          total += part.length + 1; // +1 for space
        }
      });

      const resultsString = results.join(' ');

      return resultsString + (resultsString !== text ? '...' : '');
    }

    app.config.globalProperties.$bookDemoUrl = () => {
      const store = options.store;
      const user = store.getters["Auth/getAuthUser"];

      let url = process.env.VUE_APP_CALENDAR_SCHEDULE_URL;

      if (user !== null) {
        if (!url.includes("?")) {
          url += '?';
        } else {
          const lastChar = url.charAt(url.length - 1);

          if (lastChar !== '?') {
            url += '&';
          }
        }

        let info = {
          user: {
            name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            company: user.company?.name ?? null,
            phone: user.phone,
          },
          redirection_link: "https://easas.net/profile",
        };

        url += 'info=' + encodeURIComponent(Base64.encode(JSON.stringify(info)));
      }

      return url;
    }

    app.config.globalProperties.$freeTrialUrl = () => {
      const store = options.store;
      const user = store.getters["Auth/getAuthUser"];

      return user !== null ? '/profile?nextStepModal=1' : '/register';
    }

    app.config.globalProperties.$getCookiesDomain = () => {
      let cookieDomain = document.location.hostname;

      if (cookieDomain.startsWith('www.')) {
        cookieDomain = '.' + cookieDomain.substring(4);
      } else if (cookieDomain === 'localhost') {
        // Leave it as 'localhost'
      } else {
        cookieDomain = '.' + cookieDomain;
      }

      return cookieDomain;
    }
  }
}
